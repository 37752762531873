import React from "react";

const cardsData = [
  {
    title: "Marketing Strategy Creation",
    description: "We provide a detailed, step-by-step marketing plan tailored to your audience’s needs. Additionally, we will provide all the necessary preliminary analysis and a trusted supplier list.",
  },
  {
    title: "Marketing Strategy Implementation",
    description: "We provide a full Marketing Department to implement the strategy. The department, managed by our PMM, will be assembled for your products needs.",
  },
  {
    title: "Targeted Traffic",
    description: "Reach out to more potential clients by using targeting on Facebook, Instagram, and X (Twitter). Build a strategic funnel to guide your audience from awareness to conversion, maximizing engagement and sales.",
  },
  {
    title: "Specific Traffic Mix",
    description: "Leverage specific traffic mixes from multiple platforms to optimize your reach. By combining different sources, you can fine-tune your audience targeting, boost engagement, and drive more effective conversions.",
  },
  {
    title: "Organic Shilling",
    description: "Up to 2000 messages per day about your project in a chat of your choice, you will receive a detailed report by the end of each week to track the process.",
  },
  {
    title: "Project Awareness Growth",
    description: "Over 1,000 unique posts about your project on Twitter with 100+ subscribers to increase awareness.",
  },
  {
    title: "Community / Social Media Management",
    description: "We provide community and social media management services for your preferred social network and language.",
  },
  {
    title: "KOLs Marketing",
    description: "Our network includes over 600 influencers and content creators. We prioritize your project’s budget by negotiating optimal rates with them.",
  },
  {
    title: "Stickers",
    description: "We will design a set of stickers with your mascot and key visuals, which will help your project grow organically.",
  },
  {
    title: "Web-design",
    description: "We will complete your website’s design, fully optimized for your development team.",
  },
  {
    title: "Picture Posts",
    description: "We design posts and templates tailored to your SMM ideas, aligned with your project’s style and key visuals.",
  },
  {
    title: "Animated Design",
    description: "Our team will create custom animations for reels, announcements, or any other needs.",
  },
  {
    title: "Website",
    description: "We will develop both backend and frontend to meet your website requirements.",
  },
  {
    title: "IOS and Android Apps",
    description: "Our team will develop your app from scratch with a user-oriented approach.",
  },
  {
    title: "Telegram Mini-Apps",
    description: "We will develop your Telegram Mini App or transfer your existing WebApp into Telegram.",
  },
  {
    title: "Hiring In-house Team",
    description: "We will hire top experts for your in-house team, ensuring they align with your goals and contribute to your long-term success.",
  },
  {
    title: "Hiring Part-Time Specialists",
    description: "We will hire part-time specialists to support your team, ensuring flexibility and alignment with your specific project needs.",
  },
];

const Card = ({ title, description }) => (
    <div className="bg-[#3d3d4a] text-white p-6 rounded-2xl shadow-lg w-full sm:w-1/2 lg:w-1/4 " >
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-sm mb-4 text-[#cdcdcb]">{description}</p>
      <a
      href="https://calendly.com/contact-delegate/30min"
      target="_blank"
      rel="noopener noreferrer"
      className="block"
    >
      <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-full ">
        Book a Call →
      </button>
      </a>
    </div>
  );
  
  const App = () => {
    return (
      <div className="min-h-screen bg-[#2e2e3a] p-8 flex flex-col justify-between">
        {/* Карточки */}
        <div className="flex flex-wrap gap-4 justify-center mb-8">
          {cardsData.map((card, index) => (
            <Card key={index} title={card.title} description={card.description} />
          ))}
        </div>
  
        {/* Блок с изображением */}
        <div className="flex justify-center mt-1">
          <img
            src="/Delegate.png"
            alt="Delegate"
            className="w-full max-w-xl rounded-lg"
          />
        </div>
      </div>
    );
  };
  
  export default App;
